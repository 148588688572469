.boxed {
  background-image: linear-gradient(to right bottom, #f7f701, #18f23c);
  padding: 10px;
  border-radius: 10px;
}

.shopname {
  color: red;
  text-decoration: none;
  /* background: black; */
  padding: 5px;
  width: 80px;
  text-align: center;
}

.shopname {
  animation: colorBackground 0.1s infinite;
  -webkit-animation: colorBackground 1s infinite;
  -moz-animation: colorBackground 1s infinite;
  -o-animation: colorBackground 0.1s infinite;
}
@keyframes colorBackground {
  0% {
    /* background-color: yellowgreen; */
    color: red;
  }
  50% {
    /* background-color: red; */
    color: #00ff43;
  }
}

.shopname:hover {
  /* background: yellow; */
  color: red;
}
/* .shopname:active {
  background: green;
  } */

:where(.css-dev-only-do-not-override-14wwjjs).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title,
:where(.css-dev-only-do-not-override-14wwjjs).ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #00ffea;
}

:where(.css-14wwjjs).ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
:where(.css-14wwjjs).ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #00ffea;
}

.tuiton_info {
  font-family: sans-serif;
  margin: 15px auto;
  text-align: center;
  font-size: 30px;
  max-width: 600px;
  width: 100%;
  position: relative;
}
.tuiton_info:before {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #191970;
  left: 0;
  top: 50%;
  position: absolute;
}
.tuiton_info:after {
  content: "";
  display: block;
  width: 130px;
  height: 5px;
  background: #191970;
  right: 0;
  top: 50%;
  position: absolute;
}
