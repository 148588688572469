/* Table.css */
.table-container {
  margin: 20px;
}

.table-container .table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}

.table-container .table th,
.table-container .table td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
}

.table-container .table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.table-container .table tr:nth-child(even) {
  background-color: #fafafa;
}

.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
.alignRight input {
  text-align: right;
}
.alignCenter input {
  text-align: center;
}
.ant-input-number-input {
  text-align: right !important;
  padding-right: 25px !important;
}
