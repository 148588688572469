.bodyPrint {
  font-family: Arial, sans-serif;
  size: A6;
  line-height: 7px;
  margin-top: 5px;
  margin: 5px;
  font-size: 15px;
  height: 100vh;
}

.header {
  font-size: 23px;
}
.right {
  text-align: right;
  margin-bottom: 10px;
  margin-right: 50px;
  font-style: italic;
}

/* .container {
  margin: 5px;
  border: 1px solid #ccc;
  padding: 15px;
} */

.midheader {
  text-align: center;
  margin-bottom: 20px;
  /* font-size: 30px; */
}

.item {
  margin-top: 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  margin: 10px 100px 0px 100px;
  /* padding-bottom: 20px; */
}

.total {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.slide {
  margin-bottom: 20px;
  border-top: 2px dashed #ccc;
}

.slide2 {
  margin-bottom: 20px;
  border-top: 2px solid #332c2c;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

/* @media print {
  body {
    zoom: 102%;
    zoom: 119%;
  }
} */
